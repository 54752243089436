<template>
  <v-container fluid 
    class="franchise mb-md-6 bg"
    :class="{'m-bg m-franchise' : $vuetify.breakpoint.smAndDown}"   
  >
    <v-col 
      cols="12"
      class="pa-0 mt-md-4 mt-4 d-flex justify-center"
      :class="{'mt-0' : $vuetify.breakpoint.smAndDown}"  
    >
      <div 
        class="text-md-h4 text-h5 font-weight-bold franchise-title"
        :class="{'m-text-box mb-2' : $vuetify.breakpoint.smAndDown}"
      >프랜차이즈 문의</div>
    </v-col>
    <v-col 
      cols="12" 
      class="d-flex justify-center mt-md-4 pa-0"
      :class="{'m-text-box mt-2': $vuetify.breakpoint.smAndDown}"
    >
      <form
        id="franchiseForm"
        ref="form"
        lazy-validation
        class="franchise-form"
        @submit.prevent="sendEmail"
      >
        <v-col cols="12" class="pa-0 ma-0 d-flex justify-start">
          <input 
            type="text" 
            name="name"
            placeholder="이름*"
            required
          >
        </v-col>
        <v-col cols="12" class="pa-0 ma-0 d-flex justify-start">
          <input 
            type="email" 
            name="email"
            placeholder="이메일*"
            required
          >
        </v-col>
        <v-col cols="12" class="pa-0 ma-0 d-flex justify-start">
          <input 
            type="tel" 
            name="tell"
            placeholder="연락처*"
            required
          >
        </v-col>
        <v-row class="ma-0 pa-0 d-flex align-center">
          <input
            v-model="franchiseData.realestateAddress"
            type="address"
            name="realestateAddress"
            placeholder="보유건물주소*"
            required
          >
          <div 
            :class="{'m-adress ml-3': $vuetify.breakpoint.smAndDown}"
            class="d-flex align-end ml-md-auto"
          >
            <v-btn
              color="primary-blue"
              elevation="0"
              class="ml-auto text-caption text-md-body-2 white--text"
              height="42"
              @click="openDaumPostcode()"
            >
              주소검색 
            </v-btn>  
          </div>
          
        </v-row>
        <div class="text-caption mt-1">상담가능시간*</div>
        <v-col cols="12" class="pa-0 ma-0 d-flex justify-start">
          <select
            name="possibleTime"
          >
            <option 
            v-for="time in selectItems.possibleTime" 
            :key="time"
            >{{time}}</option>
          </select>
        </v-col>
        <div class="text-caption mt-1 mb-1">마케팅 수신동의(선택)</div>
        <v-col cols="12" class="pa-0 mb-2 d-flex justify-start">
          <input 
            type="checkbox" 
            id="marketingAgreement" 
            name="marketingAgreement" 
            required
          />
          <label class="ml-2" for="marketingAgreement">마케팅 수신에 동의합니다.</label>
        </v-col>
        <div class="text-caption mt-1 mb-1">개인정보 수집 및 이용 동의*</div>
        <v-textarea
          outlined
          :value="privacyAgreementContent"
          class="ma-0 pa-0 text-caption"
          readonly
          max-height="50"
          hide-details
        ></v-textarea>
        <v-col cols="12" class="pa-0 mt-2 mb-2 d-flex justify-start">
          <input 
            type="checkbox" 
            id="privacyAgreement" 
            name="privacyAgreement" 
            required
          />
          <label class="ml-2" for="privacyAgreement">개인정보 수집 및 이용에 동의합니다.</label>
        </v-col>
        <v-col cols="12" class="pa-0 mt-4 d-flex justify-center">
          <input type="submit" value="프랜차이즈 문의하기">
        </v-col>
      </form>
    </v-col>
   
    <div ref="wrap" class="daum-postcode-wrap pa-6"> </div>      
  </v-container>
</template>
<script>
import emailjs from '@emailjs/browser';
export default {
  components: {

  },
  name: 'franchise',
  mounted() {
    
  },
  methods: {
    sendEmail() {
      const EMAIL_SERVICE_ID = "service_eebbna4"
      const EMAIL_PUBLIC_KEY = "Wn4-ubBJ-zmzOJOIo"
      const ENTERING_TEMPLATE_ID = "template_9vioy7a"
      let franchiseForm = document.getElementById('franchiseForm')
      emailjs.sendForm(EMAIL_SERVICE_ID, ENTERING_TEMPLATE_ID, this.$refs.form, EMAIL_PUBLIC_KEY)
        .then((result) => {
            console.log('SUCCESS!', result.text);
            alert('프랜차이즈 문의해주셔서 감사합니다. 빠른 시일내에 연락드리겠습니다.')
            franchiseForm.reset();
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    },
    onAddressPopup(value){
      this.dialog = value
    },
    openDaumPostcode () {
      this.isOpenPopup = true;
      const daumPostcodeWrap = this.$refs.wrap
      let themeObj = {
        bgColor: "#ffffff"
    }
      // 현재 scroll 위치를 저장해놓는다.
      const currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
      new window.daum.Postcode({
        oncomplete: (data) => {
            // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 각 주소의 노출 규칙에 따라 주소를 조합한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            let addr = '' // 주소 변수

            // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
            if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                addr = `${data.address} ${data.buildingName}`
            } else { // 사용자가 지번 주소를 선택했을 경우(J)
                addr = `${data.address} ${data.buildingName}`
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            // document.getElementById('address').value = addr
            this.franchiseData.realestateAddress = addr

            // iframe을 넣은 element를 안보이게 한다.
            // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
            daumPostcodeWrap.style.display = 'none'
            this.isOpenPopup = false;

            // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
            document.body.scrollTop = currentScroll
        },
        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        onresize: function () {
            daumPostcodeWrap.style.height = '100vh'
        },
        theme: themeObj,
        width: '100%',
        height: '100%'
      }).embed(daumPostcodeWrap)
      daumPostcodeWrap.style.display = 'block'
    },
    foldDaumPostcode () {
      const daumPostcodeWrap = this.$refs.wrap
      daumPostcodeWrap.style.display = 'none'
      this.isOpenPopup = false;
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  },
  data() {
    return {
      dialog: false,
      address: null,
      isOpenPopup: false,
      valid: true,
      franchiseData: {
        email: '',
        name: '',
        tell: '',
        realestateAddress: '',
        possibleTime: null,
        marketingAgreement: false,
        privacyAgreement: false
      },
      rules: {
        name: [
          v => !!v || '이름을 입력해주세요',
          v => (v && v.length <= 10) || '10자 이하로 작성해주세요.',
        ],
        cookingType: [],
        tell: [],
        possibleTime: [
          [v => !!v || '상담가능 시간을 선택해주세요']
        ],
        visitRoute: [
          [v => !!v || '방문 경로를 선택해주세요.']
        ],
        marketingAgreement: [],
        privacyAgreement: [],
      },
      privacyAgreementContent: `개인 정보 보호 정책 및 이용 약관,
마케팅 수신동의 약관

개인 정보 정책
주식회사 아크는 (이하 “회사“는) 고객님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호“에 관한
법률을 준수하고 있습니다.
회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며,
개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

수집하는 개인정보 항목 및 수집방법

가. 수집하는 개인정보의 항목
o 회사는 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
– 입점 상담 신청 시 : 이름 , 성별 , 휴대전화번호 , 이메일 , 상호명
– 전화 상담 시 : 이름, 휴대전화번호, 이메일, 상호명
나. 수집방법
– 홈페이지, 서면양식, 이메일, 입점 상담 신청, 전화, 팩스, 생성 정보 수집 툴을 통한 수집

개인정보의 수집 및 이용목적
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
o 입점 상담 신청 후 지속적인 연락을 통한 운영상황 업데이트
–신규 지점 홍보, 지점별 입점 프로모션 등 업데이트 된 사항 전달
o 마케팅 및 광고에 활용
–이벤트 등 광고성 정보 전달 , 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계

개인정보의 보유 및 이용기간
원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

가. 회사 내부방침에 의한 정보보유 사유
회원이 탈퇴한 경우에도 불량회원의 부정한 이용의 재발을 방지, 분쟁해결 및 수사기관의 요청에 따른 협조를 위하여,
이용계약 해지일로부터 5년간 회원의 정보를 보유할 수 있습니다.

나. 관련 법령에 의한 정보 보유 사유
전자상거래등에서의소비자보호에관한법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는
아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
o 계약 또는 청약철회 등에 관한 기록
-보존이유 : 전자상거래등에서의소비자보호에관한법률
-보존기간 : 5년
o 대금 결제 및 재화 등의 공급에 관한 기록
-보존이유: 전자상거래등에서의소비자보호에관한법률
-보존기간 : 5년
o 소비자 불만 또는 분쟁처리에 관한 기록
-보존이유 : 전자상거래등에서의소비자보호에관한법률
-보존기간 : 3년
o 로그 기록
-보존이유: 통신비밀보호법
-보존기간 : 3개월

이용약관
개인정보의 파기절차 및 방법
회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.
파기절차 및 방법은 다음과 같습니다.

o 파기절차
회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함)
내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.
별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.

o 파기방법
전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
개인정보 제공
회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

o 이용자들이 사전에 동의한 경우

o 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
이용자 및 법정대리인의 권리와 그 행사방법

o 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.

o 이용자들의 개인정보 조회,수정을 위해서는 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.

o 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.

o 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간“에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록처리하고 있습니다.
개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
회사는 귀하의 정보를 수시로 저장하고 찾아내는 “쿠키(cookie)” 등을 운용합니다.
쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.

o 쿠키 등 사용 목적
1. 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
2. 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.

o 쿠키 설정 거부 방법
1. 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
2. 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
3. 단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.

개인정보에 관한 민원서비스
회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및
개인정보보호책임자를 지정하고 있습니다.

o 개인정보보호담당자
성명 : 박현수
소속 : 주식회사 아크
전화번호 : 070-8725-7777
이메일 : work@modooceo.com 
o 귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다.
o 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
o 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
개인정보침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)
개인정보분쟁조정위원회 (kopico.go.kr / 1833-6972)
대검찰청 사이버수사과 (spo.go.kr / 지역번호+1301)
경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)

마케팅 수신 동의

1. 광고성 정보의 이용목적
회사가 제공하는 신규 지점 안내, 이벤트 등의 광고성 정보를 전자우편이나 서신우편, 문자(SMS 또는 카카오 알림톡), 푸시, 전화 등을 통해 이용자에게 제공합니다.
– 마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라 동의를 철회할 수 있습니다.
동의를 거부하시더라도 회사가 제공하는 서비스의 이용에 제한이 되지 않습니다.
단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.

2. 미동의 시 불이익 사항
개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의 거부하시더라도 서비스 이용에 제한되지 않습니다.
단, 할인, 이벤트 및 신규 지점 안내 등의 마케팅 정보 안내 서비스가 제한됩니다.

3. 서비스 정보 수신 동의 철회
회사에서 제공하는 마케팅 정보를 원하지 않을 경우 ’080수신거부 서비스’를 통해 철회를 요청할 수 있습니다.
또한 향후 마케팅 활용에 새롭게 동의하고자 할 때에는, 아크키친 대표 유선번호 ‘070-8725-7777’로 문의주시기 바랍니다.
시행일자 : 2022.07.01 
      `,
      selectItems: {
         possibleTime: [
          '언제나 상담가능(09:00~18:00)',
          '09:00~10:00',
          '10:00~11:00',
          '11:00~12:00',
          '12:00~13:00',
          '13:00~14:00',
          '14:00~15:00',
          '15:00~16:00',
          '16:00~17:00',
          '17:00~18:00',
        ],
        visitRoute: [
          '인스타그램',
          '페이스북',
          '블로그',
          '네이버검색',
          '네이버카페',
          '사업설명회',
          '창업박람회',
          '신문기사',
          '지인소개',
          '기타'
        ],
      },
      checkbox: false,
    }
  },
  computed: {

  }
}
</script>
<style lang="scss" scoped>
.bg {
  background-image: url('../../../../assets/images/franchise-bg.png');
  background-size: cover;
  background-position: center; 
  // background-color: #f7fafd;
}
.franchise {
  max-width: 100%; 
  height: 126%;
  padding-top: 110px;
} 
.franchise-title {
  width: 360px;
}
.m-franchise{
  padding-top: 56px;
}
.franchise-form {
  width: 360px;
}
.franchise-form 
input[type="text"], 
input[type="tel"],
input[type="email"],
select {
  width: 100%;
  padding: 8px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.franchise-form input[type="address"] {
  width: 70%;
  max-width: 300px;
  padding: 8px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.franchise-form input[type="submit"] {
  display: block;
  padding: 10px 30px;
  font-size: 14px;
  border: 1px solid blue;
  border-radius: 4px;
  box-sizing: border-box;
  color: blue;
}  
.daum-postcode-wrap {
  display: none;
  position: absolute;
  top: 194px;
  left: 50%;
  width: 368px;
  height: 628px !important;
  z-index: 99;
  padding: 30px;
  background-color: white;
  border: 1px solid #000000;
  margin-left: -184px;
}
.daum-postcode-fold {
  cursor: pointer;
  z-index:1
}
.title-text {
  width: 1000px !important;
}
.m-title-text {
  width: 300px !important;
}
.m-bg {
  height: 900px !important;
}
.m-size {
  width: 300px !important;
}
.m-text-box {
  width: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.m-adress {
  width: 70px !important;
}

</style>